import {
  Accordion,
  AccordionItem,
  Alert,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Textarea,
  useDisclosure
} from "@nextui-org/react";
import { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";
import axios from "axios";
import { FaMinus, FaPlus } from "react-icons/fa6";

interface IDepartments {
  doctors: any;
}

const Departments: FC<IDepartments> = ({ doctors }) => {
  const { isOpen, onClose, onOpenChange } = useDisclosure();
  const {
    isOpen: newIsOpen,
    onClose: onNewClose,
    onOpenChange: newOnOpenChange
  } = useDisclosure();
  const [departments, setDepartments] = useState<any[]>([]);
  const [selectedDoctor, setSelectedDoctor] = useState<number>();
  const [selected, setSelected] = useState<any>();
  const [departmentFormData, setDepartmentFormData] = useState<any>({
    title: "",
    description: "",
    doctor_ids: [null],
    price: 0
  });
  const [createSelectedDoctors, setCreateSelectedDoctors] = useState([null]);
  const [updateSelectedDoctors, setUpdateSelectedDoctors] = useState([null]);

  const handleOpenEdit = async (department: any) => {
    try {
      const doctorsResponse = await Promise.all(
        department.doctor_ids.map(async (doctor_id: number, i: number) => {
          const response = await axios.get(
            `https://api.stepanavanmc.am/doctors/findById/${doctor_id}`
          );
          return response.data;
        })
      );

      setUpdateSelectedDoctors(department.doctor_ids || []);

      setSelected({
        id: department.id,
        title: department.title,
        description: department.description,
        doctor_ids: department.doctor_ids,
        price: department.price
      });

      onOpenChange();
    } catch (error) {
      console.error("Ошибка при загрузке врачей:", error);
    }
  };

  const handleEdit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `https://api.stepanavanmc.am/departments/update/${selected.id}`,
        { ...selected, doctor_ids: updateSelectedDoctors }
      );
      await fetchDepartments();
      onClose();
    } catch (error) {
      console.error("Ошибка при обновлении отдела:", error);
    }
  };

  const handleAddDepartment = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      createDepartment();
      onClose();
    } catch (error) {
      console.error("Failed to add department:", error);
    }
  };

  const createDepartment = async () => {
    setDepartmentFormData(async (prev: any) => {
      try {
        const response = await axios.post(
          `https://api.stepanavanmc.am/departments/create`,
          { ...prev, doctor_ids: createSelectedDoctors }
        );
        console.log("Department created:", response.status);
        fetchDepartments();
        return { ...prev, doctor_ids: createSelectedDoctors };
      } catch (error) {
        console.error("Error creating department:", error);
        throw error;
      }
    });
  };

  const removeDoctorCreate = (index: number) => {
    setCreateSelectedDoctors(prev => prev.filter((_, i) => i !== index));
  };

  const removeDoctorUpdate = (index: number) => {
    setUpdateSelectedDoctors(prev => prev.filter((_, i) => i !== index));
  };

  const fetchDepartments = async () => {
    const response = await axios.get(
      "https://api.stepanavanmc.am/departments/findAll"
    );
    setDepartments(response.data);
  };

  useEffect(() => {
    fetchDepartments();
  }, []);

  const handleDepartmentFormChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setDepartmentFormData((prev: any) => ({
      ...prev,
      doctor_ids: createSelectedDoctors
    }));
    setDepartmentFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleChangeUpdateDoctor = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    setSelected((prev: any) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleRemoveDepartment = async (id: number) => {
    try {
      await axios.delete(
        `https://api.stepanavanmc.am/departments/delete/${id}`
      );
      await fetchDepartments();
    } catch (error) {
      console.error("Failed to delete department:", error);
    }
  };

  const addNewDoctorCreate = () => {
    setCreateSelectedDoctors((prev: any[]) => [...prev, null]); // If expected as strings
  };

  const addNewDoctorUpdate = () => {
    setUpdateSelectedDoctors((prev: any[]) => [...prev, null]); // If expected as strings
  };

  useEffect(
    () => {
      console.log(createSelectedDoctors);
    },
    [createSelectedDoctors]
  );

  return (
    <div>
      <h2 className="text-2xl mb-4">Departments</h2>
      <Alert color="default" title="Please fill out all fields" />
      <Modal isOpen={newIsOpen} placement="top" onOpenChange={newOnOpenChange}>
        <ModalContent>
          <ModalHeader>Add New Department</ModalHeader>
          <form className="w-full" onSubmit={handleAddDepartment}>
            <ModalBody>
              <Input
                name="title"
                onChange={handleDepartmentFormChange}
                value={departmentFormData.title}
                label="Department"
              />
              <Textarea
                name="description"
                onChange={handleDepartmentFormChange}
                value={departmentFormData.description}
                label="Description"
              />
              {createSelectedDoctors.map((doctor, i) =>
                <div className="flex gap-3" key={i}>
                  <Select
                    selectedKeys={doctor ? [String(doctor)] : []}
                    onSelectionChange={keys => {
                      const newDoctors: any[] = [...createSelectedDoctors];
                      newDoctors[i] = Number(keys.currentKey);
                      setCreateSelectedDoctors(newDoctors);
                    }}
                    label="Select doctor"
                  >
                    {doctors.map((doc: any) =>
                      <SelectItem key={doc.id} value={doc.id}>
                        {doc.first_name} {doc.last_name} {doc.father_name}
                      </SelectItem>
                    )}
                  </Select>
                  <Button
                    className="h-[56px]"
                    variant="ghost"
                    color={
                      i === createSelectedDoctors.length - 1
                        ? "success"
                        : "danger"
                    }
                    onClick={() =>
                      i === createSelectedDoctors.length - 1
                        ? addNewDoctorCreate()
                        : removeDoctorCreate(i)}
                  >
                    {i === createSelectedDoctors.length - 1
                      ? <FaPlus />
                      : <FaMinus />}
                  </Button>
                </div>
              )}

              <Input
                name="price"
                onChange={handleDepartmentFormChange}
                type="number"
                value={String(departmentFormData.price)}
                label="Price"
              />
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="success" variant="flat">
                Create
              </Button>
              <Button onClick={onNewClose} color="danger" variant="flat">
                Close
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <Accordion className="mt-5">
        {departments.map(({ id, title, description, doctor_ids }: any) =>
          <AccordionItem key={id} title={title}>
            <Textarea size="lg" disabled variant="flat" value={description} />
            <div className="flex justify-end mt-4 gap-3">
              <Button
                onClick={() =>
                  handleOpenEdit({ doctor_ids, title, description, id })}
                variant="flat"
                color="success"
              >
                Edit
              </Button>
              <Button
                onClick={() => handleRemoveDepartment(id)}
                variant="flat"
                color="danger"
              >
                Delete
              </Button>
            </div>
          </AccordionItem>
        )}
      </Accordion>
      <div className="mt-5 flex justify-center">
        <Button onClick={newOnOpenChange}>Add New Department</Button>
        {selected &&
          <Modal isOpen={isOpen} placement="top" onOpenChange={onOpenChange}>
            <ModalContent>
              <ModalHeader>
                Update ({selected.title})
              </ModalHeader>
              <form className="w-full" onSubmit={handleEdit}>
                <ModalBody>
                  <Input
                    name="title"
                    onChange={handleChangeUpdateDoctor}
                    value={selected.title}
                    label="Department"
                  />
                  <Textarea
                    name="description"
                    onChange={handleChangeUpdateDoctor}
                    value={selected.description}
                    label="Description"
                  />
                  {updateSelectedDoctors.map((doctor, i) =>
                    <div className="flex gap-3" key={i}>
                      <Select
                        name="doctor_id"
                        selectedKeys={doctor ? [String(doctor)] : []} // <-- Установка значения
                        onSelectionChange={keys => {
                          const newDoctors: any[] = [...updateSelectedDoctors];
                          newDoctors[i] = Number(keys.currentKey);
                          setUpdateSelectedDoctors(newDoctors);
                        }}
                        label="Select doctor"
                      >
                        {doctors.map((doc: any) =>
                          <SelectItem key={doc.id} value={doc.id}>
                            {doc.first_name} {doc.last_name} {doc.father_name}
                          </SelectItem>
                        )}
                      </Select>
                      <Button
                        className="h-[56px]"
                        variant="ghost"
                        color={
                          i === updateSelectedDoctors.length - 1
                            ? "success"
                            : "danger"
                        }
                        onClick={() =>
                          i === updateSelectedDoctors.length - 1
                            ? addNewDoctorUpdate()
                            : removeDoctorUpdate(i)}
                      >
                        {i === updateSelectedDoctors.length - 1
                          ? <FaPlus />
                          : <FaMinus />}
                      </Button>
                    </div>
                  )}
                  <Input
                    name="price"
                    onChange={handleChangeUpdateDoctor}
                    type="number"
                    value={String(selected.price)}
                    label="Price"
                  />
                </ModalBody>
                <ModalFooter>
                  <Button type="submit" color="success" variant="flat">
                    Update
                  </Button>
                  <Button onClick={onClose} color="danger" variant="flat">
                    Close
                  </Button>
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>}
      </div>
    </div>
  );
};

export default Departments;
